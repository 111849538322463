<template>
  <div class="overview w-full relative">
    <Survey
      :document-id="documentSets.map(d => d.documentSet.topic).join(';')"
      class="mb-6"
    />
    <em
      class="float-right"
      v-if="$route.params.date">Topics processed on {{ $route.params.date }}</em>
    <h1 v-if="showDescription" class="instruction">Task: get an overview of a given news topic</h1>
    <div class="instruction-box" v-if="showDescription">
      <p class="instruction">
        Below you see an overview showing you a single news topic and multiple news articles
        reporting on it. Please familiarize yourself with the topic and its
        articles. Also, understand additional information that is presented to you below, if any.
      </p>
      <p class="instruction">
        It is crucial that you get at least an understanding of the topic's main perspectives
        present in the news coverage shown below.
      </p>
      <p class="instruction">
        Once finished, click the button on the bottom of the page to continue the survey.
      </p>
    </div>
    <h1 class="mt-6 instruction" v-if="showDescription">Overview</h1>
    <span v-if="$apollo.loading"><strong>Loading...</strong></span>
    <component
      :class="cls"
      v-bind:is="$store.getters.overviewComponent"
      v-for="documentSet in documentSets"
      :key="documentSet.identifier"
      :document-set="documentSet.documentSet"
      :identifier="documentSet.identifier"
      :file="$route.params.file"
      :base-link="`/article/${documentSet.date}/${documentSet.identifier}/`"/>
  </div>
</template>

<script>
import DocumentParser from '@/libraries/DocumentParser.js';
import { loader } from '@/libraries/TopicLoader';
import Survey from '@/components/Survey.vue';

const overview = {
  name: 'overview',
  components: { Survey },
  data() {
    return {
      startTime: null,
      nextRoute: false,
      skipToIteration: false,
      documentSetLoaded: false,
      documentSets: [],
    };
  },
  mounted() {
    const loadTopic = loader(this.$route.params.file)(false);
    loadTopic.then(({
      response,
      thisFile,
      nextFile,
    }) => {
      this.documentSets = DocumentParser.parseDocumentSets(
        response.documentSets,
        this.$store.state.configuration,
        response.randomSeedString,
      );
      this.loading = false;
      if (nextFile === null) {
        this.noMoreTopics = true;
      }
    })
      .catch((e) => {
        alert(`Could not load new topic: ${e}`);
        this.loading = false;
      });
  },
  computed: {
    showDescription() {
      return process.env.VUE_APP_SHOW_NEWS_OVERVIEW_DESCRIPTION === 'true';
    },
    cls() {
      if (this.$store.state.configuration.overviewView) {
        return `overview-${this.$store.state.configuration.overviewView.toLowerCase()}`;
      }
      return '';
    },
  },
};
export default overview;
</script>
