<template>
  <div class="w-full flex flex-col">
    <div
      v-if="questions && !hideSurvey"
      class="relative px-4 overflow-hidden"
      :style="
      isDemographicsSurvey && !isExpanded ?
      'box-shadow: inset 0 -2rem 1rem -2rem #666; max-height: 10rem;' : ''"
      @click="isDemographicsSurvey ? isExpanded = true : ''"
    >
      <div v-if="isDemographicsSurvey && !participated" class="mt-4">
        Thank you for your previous feedback!
        It would be fantastic if you could further assist our
        study by providing some demographic information:
      </div>
      <div v-if="!isExpanded" class="bottom-0 absolute text-center left-0 right-0">
        Expand
      </div>
      <div v-if="!participated">
        <div
          v-for="question in questions" :key="question.id" class="pt-2 mx-auto max-w-6xl"
          :class="{'w-full h-0 overflow-hidden': question.type === 'validationQuestion'}"
        >
          <p class="mb-2 font-semibold">{{ question.question }}</p>
          <div v-if="question.type === 'likertScaleQuestion'" class="flex justify-between">
            <label
              class="block text-center"
              v-for="(answer, i) in question.answers"
              :key="answer">
              <input type="radio" :name="question.id" :value="answer" v-model="question.value"
                     @change="checkAutoSubmit">
              <span v-if="question.ticks && question.ticks[i]">
                <br/>
                <span class="text-sm">{{
                    question.ticks[i] === true ? answer : question.ticks[i]
                  }}</span>
              </span>
            </label>
          </div>
          <div v-else-if="question.type === 'freeTextQuestion'">
            <textarea
              :name="question.id"
              @focusout="checkAutoSubmit"
              class="w-full box-border border -mt-2"
              rows="3"
              v-model="question.value"></textarea>
          </div>
          <div
            v-else-if="['checkboxQuestion', 'radioQuestion'].includes(question.type)" class="-mt-1">
            <label
              class="mr-4 p-2 border rounded inline-block mt-1"
              v-for="(answer) in question.answers"
              :key="answer">
              <input
                v-model="question.value"
                @change="checkAutoSubmit"
                :type="question.type === 'radioQuestion' ? 'radio' : 'checkbox'"
                :name="question.id"
                :value="answer"/>
              {{ answer }}
            </label>
          </div>
          <div v-else-if="question.type === 'textQuestion'">
            <input
              class="w-full p-2 border rounded -mt-2"
              v-model="question.value"
              @focusout="checkAutoSubmit"
              type="text"
            />
          </div>
          <div v-else-if="question.type === 'integerQuestion'">
            <input
              class="w-full p-2 border rounded -mt-2"
              v-model="question.value"
              type="number"
              @focusout="checkAutoSubmit"
              :min="question.min"
              :max="question.max"
              step="1"
            />
          </div>
          <div v-else-if="question.type === 'validationQuestion'">
            <textarea
              v-model="question.value"
              v-bind="textareaRequired"
              tabindex="-1"
              autocomplete="off"
              class="w-full box-border border -mt-2"></textarea>
          </div>
        </div>
      </div>
      <div
          class="text-center relative"
          v-if="surveySubmissions < numSurveySheets && !participated">
        <div class="right-0 py-2 absolute inline-block">
          <button @click="hideSurvey = true">
            Hide
          </button>
          <em v-if="iframeUrl"> or </em>
          <a :href="url" v-if="iframeUrl">
            visit the article here.
          </a>
        </div>
        <div class="block text-center">
          <button
            @click="submitData"
            class="bg-gray-300 p-2 text-lg inline-block"
            style="min-width: 70%; width: 10rem;"
          >
            <span class="font-semibold">Submit</span>
          </button>
        </div>
      </div>

      <div
        class="flex align-middle justify-center flex-col"
        v-if="numSurveySheets <= surveySubmissions || participated">
        <div class="text-center">
          Thank you for participating!
          <br/>
          <br/>
          <div class="flex items-start justify-center">
            <div class="text-center mr-4">
              <button
                type="button"
                @click="hideSurvey = true"
                class="bg-gray-300 p-2 text-lg block"
                style="min-width: 30rem;"
              >
                <span class="font-semibold">Close</span>
              </button>
            </div>
            <div class="pt-2" v-if="iframeUrl">
              <button @click="hideSurvey = true">
                Hide
              </button>
              <em> or </em>
              <a :href="url">
                visit the article here.
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="$props.iframeUrl">
      <div
        class="text-center mt-8"
        v-if="showIframe === null">
        Loading <a :href="url" target="_blank">{{ url }}</a>
      </div>
      <div class="mt-10"
           v-else-if="showIframe === false"
      >
        <div
          class="warnings">
          Unfortunately, the news outlet does prevent us from hosting a preview. You can visit the
          article here: <br/><a :href="url" target="_blank">{{ url }}</a>
        </div>
      </div>
      <iframe
        v-else
        title="Preview of the news article"
        :src="url"
        class="w-full h-full border-t-4 mt-2 border-gray-700"
      ></iframe>
    </template>
  </div>
</template>

<script>

import Cookies from 'js-cookie';
import { answerRequest, questionsRequest } from '@/libraries/SurveyApi';

const SINGLE_ANSWER_SURVEYS = ['demographics'];

const REPEATED_SURVEY = 'compareOpinionsLikert';
const DEMOGRAPHICS_SURVEY = 'demographics';
const BASE_COOKIE = JSON.stringify({ hasDemographics: false, urls: [] });

export default {
  name: 'Survey',
  props: {
    iframeUrl: {
      default: null,
      type: String,
    },
    documentId: {
      type: String,
      required: true,
    },
  },
  data() {
    const url = this.$props.iframeUrl;

    const currentSurvey = this.nextSurveySheet();
    if (currentSurvey !== null) {
      this.fetchQuestions(url, currentSurvey)
        .then((data) => {
          this.showIframe = data ? data.url_iframe_loadable || false : false;
        });
    }
    return {
      url,
      surveySubmissions: 0,
      showIframe: null,
      questions: null,
      require: true,
      numSurveySheets: 2,
      hideSurvey: currentSurvey === null,
      participated: false,
      currentSurvey,
      isExpanded: false,
    };
  },
  methods: {
    fetchQuestions(url, group = null) {
      this.$store.commit('updateLoading', 1);
      return fetch(questionsRequest(url, group), { credentials: 'include' })
        .then((response) => {
          if (!response.ok) {
            console.error(response);
            throw new Error('Question loading error');
          }
          return response.json();
        })
        .then((data) => {
          const { questions } = data;
          for (const q of questions) {
            q.value = q.type === 'checkboxQuestion' ? [] : null;
          }
          this.questions = questions;
          this.$store.commit('updateLoading', -1);
        })
        .catch((error) => {
          console.error('Question loading error', error);
          this.$store.commit('updateLoading', -1);
        });
    },
    checkAutoSubmit() {
      for (const question of this.questions) {
        if (question.value === null && question.type !== 'validationQuestion') {
          return;
        }
      }
      this.submitData();
    },
    nextSurveySheet() {
      const { hasDemographics, urls } = JSON.parse(Cookies.get('surveyForms') || BASE_COOKIE);
      if (urls.includes(window.location.href)) {
        if (hasDemographics) {
          return null;
        }
        return DEMOGRAPHICS_SURVEY;
      }
      return REPEATED_SURVEY;
    },
    submitData() {
      const answers = [];
      for (const question of this.questions) {
        answers.push({
          id: question.id,
          answer: question.value,
        });
      }
      const hasAnswer = answers.some(({ answer }) => answer !== null);
      if (!hasAnswer) {
        console.warn('Not submitting because all answers are empty.');
        return;
      }
      const history = this.$store.getters.getHistory;

      fetch(answerRequest({
        id: this.$props.documentId,
        url: window.location.href,
        view: this.$store.getters.overviewPresetName,
        screen_x: window.innerHeight,
        screen_y: window.innerWidth,
        answers,
        history,
      }), { credentials: 'include' })
        .then(response => response.json())
        .then(() => {
          // this.surveyFinished = true;
          const cookie = JSON.parse(Cookies.get('surveyForms') || BASE_COOKIE);
          if (this.currentSurvey === DEMOGRAPHICS_SURVEY) {
            cookie.hasDemographics = true;
          } else {
            cookie.urls.push(window.location.href);
          }
          Cookies.set('surveyForms', JSON.stringify(cookie));
          this.surveySubmissions += 1;
          if (this.currentSurvey !== DEMOGRAPHICS_SURVEY) {
            this.currentSurvey = DEMOGRAPHICS_SURVEY;
            this.fetchQuestions(null, DEMOGRAPHICS_SURVEY);
          } else {
            this.participated = true;
          }
        })
        .catch((error) => {
          console.error('Error submitting data', error);
        });
    },
  },
  computed: {
    textareaRequired() {
      return this.require ? { required: 'required' } : {};
    },
    isDemographicsSurvey() {
      return this.currentSurvey === DEMOGRAPHICS_SURVEY;
    },
  },
};
</script>
